import { createAction } from "@reduxjs/toolkit";
import { gql } from '@apollo/client';
import { setSubscription } from "@store/reducers/subscriptionSlice";

const getCurrentSubscriptionPlan = createAction('graphql', (email) => ({
  payload: {
    gql: gql`
      query {
        subscription {
          planId
          planName
          activationDate
        }
      }
    `,
    auth: true,
    normalize: (data) => { return data.subscription },
    onSuccess: [
      (data) => setSubscription({ ...data }),
    ],
  },
}));

export { getCurrentSubscriptionPlan };