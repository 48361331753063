import React, { useState } from 'react';
import {
  Box,
  Grid,
  MenuItem,
  Paper,
  TextField,
} from '@material-ui/core';
import Button from 'elements/Button';
import { object, string } from 'yup';
import { useForm } from '@hooks/useForm';
import countryList from 'constants/country-list';
import industryList from 'constants/industry-list';
import { connect } from 'react-redux';
import { submitCompanyDetails } from '@store/actions';
import { useParams } from 'react-router';
import PageHeader from 'elements/PageHeader';

function CompanyDetailsPage(props) {
  const { token } = useParams<any>();

  const formConfig = {
    initialValues: {
      industry: '',
      otherIndustry: '',
      jobTitle: '',
      companyName: '',
      country: '',
      companyAddress: '',
      telephone: '',
    },
    validationSchema: object().shape({
      country: string().required().label('Country'),
    }),
    onSubmit: (values) => {
      props.submitCompanyDetails(values, token);
    },
  };

  const [form, onSubmit, errors] = useForm(formConfig);

  const handleCountryChange = (e) => {
    form.setFieldValue('country', e.target.value);

    const selectedCountryName = e.target.value;
    const selectedCountry = countryList.find(
      (x) => x.name === selectedCountryName
    );
    if (selectedCountry && form.values.telephone.length < 7) {
      form.setFieldValue('telephone', selectedCountry.telephoneCode + ' ');
    }
  };

  const [isOtherIndustrySelected, setIsOtherIndustrySelected] = useState(false);
  const handleIndustryChange = (e) => {
    const selectedIndustry = e.target.value;
    form.setFieldValue('industry', selectedIndustry);

    if(selectedIndustry==='Other'){
      setIsOtherIndustrySelected(true);
    } else {
      setIsOtherIndustrySelected(false);
      form.setFieldValue('otherIndustry', '');
    }
  };

  return (
    <Box display="flex" justifyContent="center">
      <Box width={{ xs: '100%', sm: '500px' }}>
        <PageHeader>We just need a few more details to complete your account verification...</PageHeader>
        <Paper elevation={5}>
          <Box padding={{ xs:2, sm: 4}} pt={{xs: 4}}>
          

            <form noValidate onSubmit={onSubmit}>
              <Grid container item direction="column" spacing={2}>
                <Grid item>
                  <TextField
                    fullWidth
                    label="Business/Company Name"
                    variant="outlined"
                    inputProps={{ style: { textTransform: 'capitalize' } }}
                    name="companyName"
                    {...form.getFieldProps('companyName')}
                    error={!!errors.companyName}
                    helperText={errors?.companyName ?? ' '}
                  />
                </Grid>

                <Grid item>
                  <TextField
                    fullWidth
                    label="Job Title"
                    variant="outlined"
                    inputProps={{ style: { textTransform: 'capitalize' } }}
                    name="jobTitle"
                    {...form.getFieldProps('jobTitle')}
                    error={!!errors.jobTitle}
                    helperText={errors?.jobTitle ?? ' '}
                  />
                </Grid>

                <Grid item>
                  <TextField
                    fullWidth
                    label="Business/Company Address"
                    variant="outlined"
                    name="companyAddress"
                    {...form.getFieldProps('companyAddress')}
                    error={!!errors.companyAddress}
                    helperText={errors?.companyAddress ?? ' '}
                  />
                </Grid>

                <Grid item xs>
                  <TextField
                    fullWidth
                    label="Country"
                    select
                    variant="outlined"
                    name="country"
                    {...form.getFieldProps('country')}
                    error={!!errors.country}
                    helperText={errors?.country ?? ' '}
                    onChange={handleCountryChange}
                  >
                    <MenuItem key="" value="" />
                    {countryList.map((country) => (
                      <MenuItem key={country.code} value={country.name}>
                        {country.emojiIcon} {country.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item>
                  <TextField
                    fullWidth
                    label="Telephone"
                    variant="outlined"
                    {...form.getFieldProps('telephone')}
                    error={!!errors.telephone}
                    helperText={errors?.telephone ?? ' '}
                  />
                </Grid>

                <Grid item>
                  <TextField
                    fullWidth
                    label="Industry Sector"
                    select
                    variant="outlined"
                    name="industry"
                    {...form.getFieldProps('industry')}
                    error={!!errors.industry}
                    helperText={errors?.industry ?? ' '}
                    onChange={handleIndustryChange}
                  >
                    <MenuItem key="''" value="''" />
                    {industryList.map((industry) => (
                      <MenuItem key={industry} value={industry}>
                        {industry}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                {isOtherIndustrySelected && (
                  <Grid item>
                    <TextField
                      fullWidth
                      label="Other Industry"
                      variant="outlined"
                      name="otherIndustry"
                      {...form.getFieldProps('otherIndustry')}
                      error={!!errors.otherIndustry}
                      helperText={errors?.otherIndustry ?? ' '}
                    />
                  </Grid>
                )}

                <Grid item>
                  <Box py={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth
                      text="Submit"
                      type="submit"
                    />
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}

const mapStateToProps = (state, ownProps = null) => ({
  ...ownProps,
});

const mapDispatchToProps = {
  submitCompanyDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetailsPage);
