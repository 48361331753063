import { v4 as uuidv4 } from 'uuid';
import { setError } from '@store/reducers/errorsSlice';
import { removePendingRequest, setLoading, setPendingRequest } from '@store/reducers/progressSlice';

const baseUrl = process.env.REACT_APP_API_URL;

const httpRequestResolver = (storeAPI) => (next) => (action) => {
  if (action.type !== 'fetch') {
    return next(action);
  }

  const requestId = uuidv4();
  storeAPI.dispatch(setPendingRequest(requestId));

  const { endpoint, method, auth, onSuccess, normalize } = action.payload;
  const body = JSON.stringify(action.payload.body);

  const headers: any = {
    'Content-Type': 'application/json',
  };

  if (auth) {
    const state = storeAPI.getState();
    const { token } = state.signIn;
    headers.Authorization = `Bearer ${token}`;
  }

  fetch(`${baseUrl}${endpoint}`, { method, headers, body })
    .then((response) => {
      response
        .text()
        .then((responseBody) => {
          const data = responseBody ? JSON.parse(responseBody) : {};
          if (response.ok) {

            const normalized = normalize ? normalize(data) : data;

            if (Array.isArray(onSuccess)) {
              onSuccess.forEach(onSuccessAction => {
                storeAPI.dispatch(onSuccessAction(normalized));
              });
            } else {
              storeAPI.dispatch(onSuccess(normalized));
            }

          } else {
            storeAPI.dispatch(setError({ error: data }));
          }
        })
        .catch((error) => {
          storeAPI.dispatch(setError({ error }));
        });
    })
    .catch((error) => {
      storeAPI.dispatch(setError({ error }));
    })
    .finally(() => {
      storeAPI.dispatch(removePendingRequest(requestId));
      return next(action);
    });
};

export default httpRequestResolver;
