import { useState } from 'react';
import { useFormik } from 'formik';

const useForm = (config) => {
  const [enableValidation, setEnableValidation] = useState(false);

  const formikConfig = {
    initialValues: config.initialValues,
    validationSchema: config.validationSchema,
    validateOnChange: enableValidation,
    validateOnBlur: enableValidation,
    enableReinitialize: config.enableReinitialize,
    onSubmit: config.onSubmit,
  };

  const form = useFormik(formikConfig);

  const preSubmit = (e) => {
    setEnableValidation(true);
    form.handleSubmit(e);
  };

  return [form, preSubmit, form.errors, form.setFieldValue] as const;
};

export { useForm };
