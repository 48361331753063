import React from 'react';
import { connect } from 'react-redux';

import TopBar from 'components/TopBar';
import LoadingIndicator from 'components/LoadingIndicator';
import Notifications from 'components/Notifications';

import SessionLayout from './SessionLayout';
import DashboardLayout from './DashboardLayout';
import { Background } from 'components';
import { Container } from '@material-ui/core';

const MainLayout = (props) => (
  <>
    <LoadingIndicator />
    <Notifications />
    <TopBar />
    <Background>
      <Container maxWidth="lg">
        {props.isLoggedIn ? <DashboardLayout /> : <SessionLayout />}
      </Container>
    </Background>
  </>
);

const mapStateToProps = (state, ownProps = null) => ({
  isLoggedIn: state.signIn.isLoggedIn,
  ...ownProps,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
