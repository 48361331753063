import { createSlice } from '@reduxjs/toolkit';

export const signInSlice = createSlice({
  name: 'signIn',
  initialState: {
    user: null,
    token: null,
    loading: false,
    isLoggedIn: false,
    profile: null,
  },
  reducers: {
    signOut: (state) => {
      state.user = null;
      state.isLoggedIn = false;
    },
    signIn: (state, { payload }) => {
      state.user = payload.user;
      state.token = payload.token;
      state.isLoggedIn = true;
    },
    setProfileData: (state, { payload }) => {
      state.profile = payload.profileData;
      state.user.firstName = payload.profileData.firstName;
      state.user.lastName = payload.profileData.surname;
    }
  },
});

export const { signOut, signIn, setProfileData } = signInSlice.actions;

export default signInSlice.reducer;
