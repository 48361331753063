import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Container,
  Grid,
  Modal,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import styled from 'styled-components';
import blueGrey from '@material-ui/core/colors/blueGrey';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import CheckIcon from '@material-ui/icons/CheckRounded';
import AddIcon from '@material-ui/icons/AddRounded';
import Button from 'elements/Button';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PageHeader from 'elements/PageHeader';

const PriceHolder = styled.span`
  font-size: 42px;
  font-weight: 900;
`;

const UnitHolder = styled.span`
  font-size: 24px;
  font-weight: 500;
`;

const ShortDescription = styled.div`
  font-size: 16px;
  opacity: 0.6;
`;

const FeaturesSection = styled('div')`
  background-color: ${blueGrey[50]};
`;

const GreenCheckIcon = styled(CheckIcon)`
  color: ${green[500]};
  stroke: ${green[500]};
  stroke-width: 2;
  margin-right: 10px;
`;

const RedAddIcon = styled(AddIcon)`
  color: ${red[300]};
  stroke: ${red[300]};
  stroke-width: 2;
  margin-right: 10px;
`;

const ModalContainer = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalPaper = styled(Paper)`
  width: 400px;
  padding: 16px;
  outline: 0;
`;

const freePlan = {
  index: 0,
  id: 'Free',
  name: 'STANDARD',
  price: 'Free',
  extraFee: '',
  description:
    'One active user, unlimited guest users. Access to all features except Public Risk Directory and Data Export',
  buttonText: 'Start with Free Plan',
  features: [
    { text: 'Customisable System Parameters', icon: <GreenCheckIcon /> },
    { text: 'Unlimited Projects & Risks', icon: <GreenCheckIcon /> },
    {
      text: 'Secure Cloud Synchronization & Offline Use',
      icon: <GreenCheckIcon />,
    },
    { text: 'Automatic Risk Ranking & Closure', icon: <GreenCheckIcon /> },
    { text: 'Manual Risk Closure Option', icon: <GreenCheckIcon /> },
    { text: 'Parent-Consequential Risk Link', icon: <GreenCheckIcon /> },
    { text: 'Risk Occurrence & Action Alerts', icon: <GreenCheckIcon /> },
    { text: 'Smart Search', icon: <GreenCheckIcon /> },
    { text: 'Generate & Print Reports', icon: <GreenCheckIcon /> },
  ],
  featuresBoxHeight: '340px',
  headerBoxHeight: '120px',
  showAdditionalInfoButton: false,
  showPriceInfoPopover: false,
};

const proPlan = {
  index: 1,
  id: 'Pro',
  name: 'PROFESSIONAL',
  price: '£15',
  extraFee: '',
  unit: ' per user/month',
  description: 'Full access to all features. Add or remove users at any time.',
  buttonText: 'Go with PRO Plan',
  features: [
    {
      text: 'All features available with free plan',
      icon: <GreenCheckIcon />,
    },
    { text: 'Public Risk Directory Access', icon: <RedAddIcon /> },
    { text: 'Data Export', icon: <RedAddIcon /> },
    { text: 'Custom User Count', icon: <RedAddIcon /> },
  ],
  featuresBoxHeight: '330px',
  headerBoxHeight: '155px',
  showAdditionalInfoButton: true,
  showPriceInfoPopover: true,
};

const enterprisePlan = {
  index: 2,
  id: 'Enterprise',
  name: 'ENTERPRISE',
  price: 'Let′s talk',
  unit: '',
  extraFee: '',
  description: 'Local server hosting, custom features and integrations',
  buttonText: 'Contact US',
  features: [
    {
      text: 'All features available with PRO plan',
      icon: <GreenCheckIcon />,
    },
    { text: 'Local Server Hosting', icon: <RedAddIcon /> },
    { text: 'Integrate with over 1000 Business Apps', icon: <RedAddIcon /> },
    { text: 'Primavera P6 Integration ', icon: <RedAddIcon /> },
    {
      text: 'Salesforce, QuickBooks, JIRA, Asana Integration',
      icon: <RedAddIcon />,
    },
    { text: 'Unlimited Users', icon: <RedAddIcon /> },
  ],
  featuresBoxHeight: '365px',
  headerBoxHeight: '120px',
  showAdditionalInfoButton: false,
  showPriceInfoPopover: false,
};

const PlanBox = (props) => {
  const dispatch = useDispatch();

  function handlePlanSelectionClick() {
    if (props.id === 'Enterprise') {
      window.open('https://www.project-risk-manager.com/contact/', '_blank');
    } else {
      dispatch(push(`/sign-up/create-an-account/${props.id}`));
    }
  }

  const [showUpgradeInfoModal, setShowUpgradeInfoModal] = React.useState(false);

  function handleUpgradeInfoClick() {
    setShowUpgradeInfoModal(true);
  }

  function onUpgradeInfoClose() {
    setShowUpgradeInfoModal(false);
  }

  return (
    <Box>
      <Grid container direction="column">
        <Box p={4} minHeight={props.headerBoxHeight}>
          <Grid item>
            <Chip color="primary" label={props.name} />
          </Grid>
          <Grid item>
            <Box mt={1} mb={1}>
              <PriceHolder>{props.price}</PriceHolder>
              <UnitHolder>{props.unit}</UnitHolder>
              {props.showPriceInfoPopover && (
                <Tooltip
                  title="Fees are payable in advance on a monthly auto-renewable subscription basis. New users can be added, or existing users removed at any time and will only incur charges for the days they are registered on your monthly plan."
                  arrow
                  placement="top"
                >
                  <InfoOutlinedIcon color="secondary" />
                </Tooltip>
              )}
            </Box>
          </Grid>
          <Grid item>
            <ShortDescription>{props.description}</ShortDescription>
          </Grid>
        </Box>
      </Grid>

      <FeaturesSection>
        <Grid>
          <Box px={4} py={1} minHeight={props.featuresBoxHeight}>
            {props.features.map((x) => (
              <Grid item key={x.text}>
                <Box my={1.5} display="flex" alignItems="center">
                  {x.icon} {x.text}
                </Box>
              </Grid>
            ))}
          </Box>
        </Grid>

        <Box px={4} pb={2}>
          <Grid container alignItems="center" justify="center" spacing={2}>
            {props.showAdditionalInfoButton && (
              <Grid item>
                <Button
                  size="small"
                  fullWidth
                  text="Upgrade/Downgrade policy"
                  onClick={handleUpgradeInfoClick}
                />
                <ModalContainer
                  open={showUpgradeInfoModal}
                  onClose={onUpgradeInfoClose}
                >
                  <ModalPaper elevation={4}>
                    <Box my={2}>
                      <Box my={1}>
                        <Typography variant="subtitle2">
                          You can change the number of users in your Pro Plan at
                          any time and the difference in cost will be debited or
                          credited to your account based on the number of days
                          remaining in your monthly subscription.
                        </Typography>
                      </Box>
                      <Box my={1}>
                        <Typography variant="subtitle2">
                          If you downgrade your subscription from Pro to Free,
                          the monthly auto-renewable charges will cancelled
                          immediately and your account will be credited based on
                          the number of days remaining in your monthly
                          subscription.
                        </Typography>
                      </Box>
                      <Box my={1}>
                        <Typography variant="subtitle2">
                          Note: When downgrading the number of active users, or
                          downgrading from the Pro to Free Plan, access rights
                          of all users (except the subscriber) will
                          automatically be downgraded to "Guest" status.
                          Subscribers will need to manually re-assign access
                          rights to active users up to the maximum permitted by
                          your plan.
                        </Typography>
                      </Box>
                    </Box>
                  </ModalPaper>
                </ModalContainer>
              </Grid>
            )}
            <Grid container item xs={12}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                text={props.buttonText}
                onClick={handlePlanSelectionClick}
              />
            </Grid>
          </Grid>
        </Box>
      </FeaturesSection>
    </Box>
  );
};

function SubscriptionSelectionPage() {
  return (
    <>
      <PageHeader>Select Your Price Plan</PageHeader>
      <Box pb={8}>
        <Grid container item direction="column" alignItems="center" spacing={4}>
          <Grid container item spacing={0}>
            <Grid item lg xs={12}>
              <Box py={2}>
                <Paper elevation={5}>
                  <PlanBox {...freePlan} />
                </Paper>
              </Box>
            </Grid>

            <Grid item lg xs={12}>
              <Paper
                elevation={10}
                style={{ zIndex: 100, position: 'relative' }}
              >
                <PlanBox {...proPlan} />
              </Paper>
            </Grid>

            <Grid item lg xs={12}>
              <Box py={2}>
                <Paper elevation={5}>
                  <PlanBox {...enterprisePlan} />
                </Paper>
              </Box>
            </Grid>
          </Grid>

          <Container>
            <Box display="flex" justifyContent="center" my={4}>
              <Typography variant="h4">FAQ</Typography>
            </Box>
          </Container>

          <Container maxWidth="md">
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">
                  How long can I use the application for free as a Standard plan
                  subscriber?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  The Standard plan is free for life, but restricts you to being
                  the only active user. To assign multiple active users, please
                  choose our Pro plan.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">
                  What are the limitations of the Standard plan?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  The Standard plan does not give you access to the Public Risk
                  Directory, nor does it allow you to export your risk data.
                  This plan only allows one active user.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">
                  What are the advantages of the Professional plan?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  The Pro plan allows full access to all the application's
                  features and functionality with the ability to select the
                  exact number of active users you require.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">
                  How many users can simultaneously use the application?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  All active users can use the application simultaneously
                  without restriction.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">
                  Can I have the application installed and hosted on my local
                  server?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Yes, this is one of our Enterprise plan features. Please
                  contact us for more details.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Container>
        </Grid>
      </Box>
    </>
  );
}

export default SubscriptionSelectionPage;
