import { createSlice } from '@reduxjs/toolkit';

export const progressSlice = createSlice({
  name: 'progress',
  initialState: {
    loading: false,
    pendingRequests: [],
  },
  reducers: {
    setPendingRequest: (state, action) =>{
      state.pendingRequests.push(action.payload);
      state.loading = true;
    },
    removePendingRequest: (state, action) =>{
      state.pendingRequests = state.pendingRequests.filter((x) => x !== action.payload);
      if(state.pendingRequests.length === 0){
        state.loading = false;
      }
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { setLoading, setPendingRequest, removePendingRequest } = progressSlice.actions;

export default progressSlice.reducer;
