import React from 'react';
import { Box, Grid, Paper, TextField, Typography } from '@material-ui/core';
import Button from 'elements/Button';
import { useForm } from '@hooks/useForm';
import { object, string, ref } from 'yup';
import { setPassword } from '@store/actions';
import { useLocation, useParams } from 'react-router-dom';
import { connect } from 'react-redux';

const SetPasswordPage = (props) => {

  const { token }: any = useParams();
  const { search } = useLocation();
  const tokenFromQueryString = new URLSearchParams(search).get('token');

  const [form, onSubmit, errors] = useForm({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
      token: token || tokenFromQueryString || '',
    },
    validationSchema: object().shape({
      newPassword: string().required().min(6).label('New Password'),
      confirmPassword: string().required().oneOf(
        [ref('newPassword'), null],
        'Passwords must match'
      ),
      token: string().required().min(4).label('Token'),
    }),
    onSubmit: (values) => {
      props.setPassword(values.token, values.newPassword);
    },
  });


  return (
    <Box display="flex" justifyContent="center" mt="10vh">
      <Box width={{ xs: '100%', sm: '500px' }}>
        <Paper elevation={5}>
          <Box padding={4}>
            <form onSubmit={onSubmit}>
              <Grid container direction="column" spacing={4}>
                <Grid item>
                  <Box display="flex" justifyContent="center">
                    <Typography variant="h6">Reset Your Password</Typography>
                  </Box>
                </Grid>
                <Grid item>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    type="password"
                    id="newPassword"
                    name="newPassword"
                    label="New Password"
                    autoComplete="new-password"
                    {...form.getFieldProps('newPassword')}
                    error={!!errors.newPassword}
                    helperText={errors?.newPassword ?? ' '}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="password"
                    name="confirmPassword"
                    label="Confirm Password"
                    autoComplete="new-password"
                    {...form.getFieldProps('confirmPassword')}
                    error={!!errors.confirmPassword}
                    helperText={errors?.confirmPassword ?? ' '}
                  />
                </Grid>
                <Grid item>
                  <Box py={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth
                      text="RESET"
                      type="submit"
                    />
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state, ownProps = null) => ({
  ...ownProps,
});

const mapDispatchToProps = {
  setPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(SetPasswordPage);
