import { Box, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import MailOutlineRoundedIcon from '@material-ui/icons/MailOutlineRounded';

const ClosingMessagePage = (props) => {
  return (
  <Box display="flex" justifyContent="center">
    <Box maxWidth="700px" pt={{xs:1, sm: 4}}>
      <Paper elevation={5}>
        <Box p={5}>
          <Box display="flex" justifyContent="center">
            <MailOutlineRoundedIcon style={{ fontSize: 140 }} />
          </Box>
          <Typography variant="h6" align="center">
            {props.message}
          </Typography>
        </Box>
      </Paper>
    </Box>
  </Box>
)};

const mapStateToProps = (state, ownProps = null) => ({
  ...ownProps,
  message: state.errors.successMessage,
});

export default connect(mapStateToProps)(ClosingMessagePage);