import {
  AppBar,
  Avatar,
  Box,
  ButtonBase,
  Chip,
  Container,
  Divider,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import Button from 'elements/Button';
import { push } from 'connected-react-router';
import MenuIcon from '@material-ui/icons/Menu';
import prmLogo from 'assets/img/prm-logo.png';
import {
  logout,
  initStripeSession,
  getCurrentSubscriptionPlan,
} from '@store/actions';

const TopBar = (props) => {
  const dispatch = useDispatch();

  const isLoggedIn = props.isLoggedIn;
  const getCurrentSubscriptionPlan = props.getCurrentSubscriptionPlan;

  useEffect(() => {
    if(isLoggedIn){
      getCurrentSubscriptionPlan();
    }
  }, [isLoggedIn, getCurrentSubscriptionPlan]);

  function handleProfileSettingsClick() {
    dispatch(push('/profile'));
  }

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const [drawerState, setDrawerState] = React.useState({ isOpen: false });

  const toggleDrawer = () => {
    setDrawerState({ isOpen: !drawerState.isOpen });
  };

  const getUserNameInitials = (user) => {
    if (user && user.firstName && user.lastName) {
      return `${user.firstName
        .slice(0, 1)
        .toUpperCase()}${user.lastName[0].toUpperCase()}`;
    }
    return '';
  };

  const handleSubscriptionButtonClick = (e) => {
    e.preventDefault();
    props.initStripeSession();
  };

  const UserAvatar = ({ user, isMobile, subscription }) => (
    <Grid container spacing={1} alignItems="center">
      <Grid item>
        <Chip variant="outlined" color="secondary" label={subscription.planName}/>
      </Grid>
      <Grid item>
        <Typography
          variant="subtitle2"
          style={{ color: isMobile ? 'initial' : 'whitesmoke' }}
        >
          {user.firstName} {user.lastName}
        </Typography>
      </Grid>
      <Grid item>
        <Avatar>{getUserNameInitials(user)}</Avatar>
      </Grid>
    </Grid>
  );

  const Logo = () => (
    <a href="https://www.project-risk-manager.com/" target="blank">
      <img src={prmLogo} alt="prm logo" />
    </a>
  );

  const desktopTopBar = (
    <Grid container alignItems="center">
      <Grid item xs={2}>
        <Logo />
      </Grid>
      <Grid item xs={7}>
        <Grid container spacing={4}>
          <Grid item>
            <Button whitetext="true" variant="text" href="/downloads">
              Downloads
            </Button>
          </Grid>
          {props.isLoggedIn && (
            <Grid item>
              <Button
                whitetext="true"
                variant="text"
                onClick={handleSubscriptionButtonClick}
              >
                Manage Subscriptions
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={3}>
        <Grid container spacing={2} justify="flex-end">
          {props.isLoggedIn ? (
            <Grid item>
              <ButtonBase
                onClick={handleMenuClick}
                style={{ borderRadius: '15px', padding: '5px' }}
                aria-controls="profile-menu"
                aria-haspopup="true"
              >
                <UserAvatar
                  user={props.user}
                  isMobile={false}
                  subscription={props.subscription}
                />
              </ButtonBase>
            </Grid>
          ) : (
            <>
              <Grid item>
                <Button
                  whitetext="true"
                  type="outlined"
                  text="Log In"
                  href="/log-in"
                />
              </Grid>
              <Grid item>
                <Button
                  whitetext="true"
                  type="outlined"
                  text="Sign Up"
                  href="/sign-up"
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );

  const mobileTopBar = (
    <>
      <Grid container alignItems="center">
        <Grid container item justify="flex-start" xs={8}>
          <Logo />
        </Grid>
        <Grid container item justify="flex-end" xs={4}>
          <IconButton style={{ padding: 0 }} onClick={toggleDrawer}>
            <MenuIcon fontSize="large" style={{ color: 'white' }} />
          </IconButton>
        </Grid>
      </Grid>
      <Drawer
        anchor="right"
        open={drawerState.isOpen}
        onClick={toggleDrawer}
        onClose={toggleDrawer}
      >
        <Box p={2} py={4}>
          <Grid container direction="column" spacing={2}>
            {props.isLoggedIn ? (
              <>
                <Grid item>
                  <UserAvatar
                    user={props.user}
                    isMobile={true}
                    subscription={props.subscription}
                  />
                </Grid>
                <Divider />
                <Grid item>
                  <Button variant="text" onClick={handleProfileSettingsClick}>
                    Profile Settings
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="text" onClick={props.logout}>
                    Logout
                  </Button>
                </Grid>
              </>
            ) : (
              <>
                <Grid item>
                  <Button variant="text" href="/log-in">
                    Log In
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="text" href="/sign-up">
                    Sign Up
                  </Button>
                </Grid>
              </>
            )}
            <Divider />
            <Grid item>
              <Button variant="text" href="/downloads">
                Downloads
              </Button>
            </Grid>
            {props.isLoggedIn && (
              <Grid item>
                <Button variant="text" onClick={handleSubscriptionButtonClick}>
                  Manage Subscriptions
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
      </Drawer>
    </>
  );

  return (
    <AppBar position="relative" color="transparent" elevation={0}>
      <Toolbar>
        <Container maxWidth="lg" disableGutters>
          <Hidden mdUp>{mobileTopBar}</Hidden>
          <Hidden smDown>{desktopTopBar}</Hidden>
        </Container>
      </Toolbar>
      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClick={handleMenuClose}
        onClose={handleMenuClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MenuItem onClick={handleProfileSettingsClick}>
          Profile Settings
        </MenuItem>
        <MenuItem onClick={props.logout}>Logout</MenuItem>
      </Menu>
    </AppBar>
  );
};

const mapStateToProps = (state, ownProps = null) => ({
  isLoggedIn: state.signIn.isLoggedIn,
  user: state.signIn.user,
  subscription: state.subscription,
  ...ownProps,
});

const mapDispatchToProps = {
  logout,
  initStripeSession,
  getCurrentSubscriptionPlan,
};

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
