import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Backdrop, CircularProgress } from '@material-ui/core';

const LoadingIndicator = (props) => {
  return (
    <Backdrop open={props.show} style={{ zIndex: 100 }}>
      <CircularProgress
        color="primary"
        style={{ height: '20vmin', width: '20vmin' }}
      />
    </Backdrop>
  );
};

const mapStateToProps = (state, ownProps = null) => ({
  show: state.progress.loading,
  ...ownProps,
});

export default connect(mapStateToProps)(LoadingIndicator);
