const { createAction } = require('@reduxjs/toolkit');
const { setProfileData } = require('@store/reducers/signInSlice');

const loadProfileData = createAction('fetch', () => ({
  payload: {
    endpoint: 'contacts/current',
    method: 'GET',
    auth: true,
    normalize: (response) => {
      // eslint-disable-next-line camelcase
      const profileData = response;
      return { profileData };
    },
    onSuccess: setProfileData,
  },
}));

const updateProfileData = createAction('fetch', (changes) => ({
  payload: {
    endpoint: 'contacts/current',
    method: 'POST',
    auth: true,
    body: changes,
    normalize: (response) => {
      const profileData = response;
      return { profileData };
    },
    onSuccess: setProfileData,
  },
}));

export { loadProfileData, updateProfileData };
