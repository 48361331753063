import React from 'react';
import { connect } from 'react-redux';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { Paper } from '@material-ui/core';
import { spacing } from '@material-ui/system';
import icon from 'assets/img/prm-icon.png';

import { object, string } from 'yup';
import { Button, Link } from 'elements';

import { login } from '@store/actions';
import { useForm } from '@hooks/useForm';

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Logo = styled.img`
  ${spacing}
`;

const LogInForm = (props) => {
  const [form, onSubmit, errors] = useForm({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: object().shape({
      username: string().email().required().label('Email'),
      password: string().required().label('Password'),
    }),
    onSubmit: (values) => {
      props.login(values.username, values.password);
    },
  });

  const classes = useStyles();

  return (
    <Paper elevation={3}>
      <Box
        display="flex"
        flexDirection="column"
        p={{ xs: 2, sm: 4 }}
        alignItems="center"
      >
        <Logo m={2} src={icon} alt="PRM logo" />
        <form className={classes.form} onSubmit={onSubmit} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Email Address"
            name="username"
            autoComplete="email"
            autoFocus
            {...form.getFieldProps('username')}
            error={!!errors.username}
            helperText={errors?.username ?? ' '}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            {...form.getFieldProps('password')}
            error={!!errors.password}
            helperText={errors?.password ?? ' '}
          />
          <Grid container>
            <Grid item xs>
              <Link to="/reset-password-request" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link to="/sign-up" variant="body2">
                Don&apos;t have an account? Sign Up
              </Link>
            </Grid>
          </Grid>
          <Grid container item justify="center" className={classes.submit}>
            <Button
              variant="contained"
              size="large"
              fullWidth
              color="primary"
              text="Log In"
              type="submit"
            />
          </Grid>
        </form>
      </Box>
    </Paper>
  );
};

const mapStateToProps = (state, ownProps = null) => ({
  ...ownProps,
});

const mapDispatchToProps = {
  login,
};

export default connect(mapStateToProps, mapDispatchToProps)(LogInForm);
