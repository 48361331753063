import React from 'react';
import { Box } from '@material-ui/core';
import { LogInForm } from 'components';
import PageHeader from 'elements/PageHeader';

function LogInPage() {
  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        style={{ position: 'relative' }}
      >
        <PageHeader>Log In</PageHeader>
        <Box width={{ xs: '100%', sm: '400px' }} >
          <LogInForm />
        </Box>
      </Box>
    </>
  );
}

export default LogInPage;
