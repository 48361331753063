import { Box, Grid, Paper, TextField, Typography } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import Button from 'elements/Button';
import { useForm } from '@hooks/useForm';
import { object, string } from 'yup';
import { requestResetPassword } from '@store/actions';

const ResetPasswordRequestedPage = () => {
  const dispatch = useDispatch();

  const [form, onSubmit, errors] = useForm({
    initialValues: {
      email: ''
    },
    validationSchema: object().shape({
      email: string().email().required().label('Email'),
    }),
    onSubmit: (values) => {
      dispatch(requestResetPassword(values.email));
    }
  })

  return (
    <Box display="flex" justifyContent="center" mt="10vh">
      <Box width={{ xs: '100%', sm: '500px' }}>
        <Paper elevation={5}>
          <Box padding={4}>
            <form onSubmit={onSubmit}>
              <Grid container direction="column" spacing={4}>
                <Grid item>
                  <Box py={2} display="flex" justifyContent="center">
                    <Typography variant="h6">Reset your password</Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2">Enter the email address associated with your account and we&apos;ll send you a link to reset your password.</Typography>
                  </Box>
                </Grid>
                <Grid item>
                  <TextField
                    fullWidth
                    id="email"
                    label="Email"
                    variant="outlined"
                    autoFocus
                    autoComplete='email'
                    error={!!errors.email}
                    helperText={errors?.email ?? ' '}
                    {...form.getFieldProps('email')}
                  />
                </Grid>
                <Grid item>
                  <Box py={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth
                      text="Continue"
                      type="submit"
                    />
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default ResetPasswordRequestedPage;
