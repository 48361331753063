import React from 'react';
import { Button as MatButton } from '@material-ui/core';
import { Link } from 'react-router-dom';

export default function Button(props) {
  const color = props.color || 'inherit';

  const whiteTextStyle = props.whitetext ? {color:"rgb(255 255 255 / 75%)"} : {};

  const matButtonProps = { ...props };
  if (props.href) {
    matButtonProps.component = Link;
    matButtonProps.to = props.href;
  }

  const matButton = (
    <MatButton color={color} {...matButtonProps} style={whiteTextStyle}>
      {props.text ?? props.children}
    </MatButton>
  );

  return matButton;
}
