import { withTheme } from '@material-ui/core';
import styled from 'styled-components';

const TopBackground = withTheme(styled.div`
  background-color:  ${props => props.theme.palette.primary.main};
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  clip-path: polygon(0 0,104% 0,100% 27%,0 54%);
`);

const AccentColorBackground = withTheme(styled.div`
  background-color: ${props => props.theme.palette.secondary.dark};
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  clip-path: polygon(100% 32%,0 51%,100% 29%);
`);

const BottomBackground = withTheme(styled.div`
  background-color: ${props => props.theme.palette.primary.main};
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  clip-path: polygon(100% 95%,52% 100%,100% 100%);
`);

const Content = styled.div`
  position: relative;
`;

function Background(props) {
  return (
    <>
      <TopBackground></TopBackground>
      <AccentColorBackground></AccentColorBackground>
      <BottomBackground></BottomBackground>
      <Content>{props.children}</Content>
    </>
  );
}

export default Background;
