import { v4 as uuidv4 } from 'uuid';
import { setError } from '@store/reducers/errorsSlice';
import { removePendingRequest, setLoading, setPendingRequest } from '@store/reducers/progressSlice';
import { ApolloClient, InMemoryCache } from '@apollo/client';

const baseUrl = process.env.REACT_APP_API_URL;

const gqlResolver = (storeAPI) => (next) => (action) => {
  if (action.type !== 'graphql') {
    return next(action);
  }

  const requestId = uuidv4();
  storeAPI.dispatch(setPendingRequest(requestId));

  const { gql, auth, onSuccess, normalize } = action.payload;

  const headers = {};

  if (auth) {
    const state = storeAPI.getState();
    const { token } = state.signIn;
    headers['Authorization'] = `Bearer ${token}`;
  }

  const apolloClient = new ApolloClient({
    uri: `${baseUrl}graphql`,
    cache: new InMemoryCache(),
    headers
  });

  apolloClient.query({ query: gql, })
    .then((response)=>{

      const hasErrors = response.error || (response.errors && response.errors.length > 0);
      if(hasErrors){
        storeAPI.dispatch(setError({ error: response.error ||  response.errors[0]}));
        return;
      }
      
      const normalized = normalize ? normalize(response.data) : response;

      if (Array.isArray(onSuccess)) {
        onSuccess.forEach(onSuccessAction => {
          storeAPI.dispatch(onSuccessAction(normalized));
        });
      } else {
        storeAPI.dispatch(onSuccess(normalized));
      }

    }).catch((error) => {
      storeAPI.dispatch(setError({ error }));
    })
    .finally(() => {
      storeAPI.dispatch(removePendingRequest(requestId));
      return next(action);
    });
};

export default gqlResolver;
