import { createSlice } from '@reduxjs/toolkit';

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState: {
    activationDate: null,
    planId: null,
    planName: null,
  },
  reducers: {
    setSubscription: (state, action) => {
      const subscription = action.payload;
      state.activationDate = subscription.activationDate;
      state.planId = subscription.planId;
      state.planName = subscription.planName;
    },
  },
});

export default subscriptionSlice.reducer;

export const { setSubscription } = subscriptionSlice.actions;
