import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import DownloadsPage from 'containers/dashboard/DownloadsPage';
import LogInPage from './session/LogInPage';
import SubscriptionSelectionPage from './session/SubscriptionSelectionPage';
import AccountCredentialsPage from './session/AccountCredentialsPage';
import CompanyDetailsPage from './session/CompanyDetailsPage';
import ResetPasswordRequestedPage from './session/ResetPasswordRequestPage';
import ClosingMessagePage from './session/ClosingMessagePage';
import SetPasswordPage from './session/SetPasswordPage';
import CreatePasswordPage from './session/CreatePasswordPage';

const SessionLayout = () => (
  <Switch>
    <Route path="/log-in">
      <LogInPage />
    </Route>
    <Route path="/sign-up">
      <Switch>
        <Route path="/sign-up/create-an-account/:subscriptionPlanId">
          <AccountCredentialsPage />
        </Route>
        <Route path="/sign-up/company-details/:token">
          <CompanyDetailsPage />
        </Route>
        <Route path="/sign-up/create-password/:token">
          <CreatePasswordPage />
        </Route>
        <Route path="">
          <SubscriptionSelectionPage />
        </Route>
      </Switch>
    </Route>
    <Route path="/check-email">
      <ClosingMessagePage />
    </Route>
    <Route path="/reset-password-request">
      <Switch>
        <Route path="">
          <ResetPasswordRequestedPage />
        </Route>
      </Switch>
    </Route>
    <Route path="/set-password/:token">
      <SetPasswordPage />
    </Route>
    <Route path="/profile/setPassword">
      <SetPasswordPage />
    </Route>
    <Route exact path="/downloads">
      <DownloadsPage />
    </Route>
    <Route path="">
      <Redirect to="/log-in" />
    </Route>
  </Switch>
);

const mapStateToProps = (state, ownProps = null) => ({
  ...ownProps,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SessionLayout);
