const industryList = [
  'Aerospace',
  'Agriculture',
  'Automotive',
  'Banking & Finance',
  'Building & Infrastructure',
  'Defence',
  'Education & Training',
  'Engineering & Construction',
  'Entertainment & Media',
  'Healthcare',
  'Industrial Manufacturing',
  'IT',
  'Mineral & Chemical Processing',
  'Mining',
  'Oil & Gas',
  'Paper & Packaging',
  'Pharmaceutical',
  'Power & Energy',
  'Research & Technology',
  'Retail & Consumer',
  'Telecommunications',
  'Transportation & Logistics',
  'Travel & Leisure',
  'Water & Waste',
  'Other',
];

export default industryList;
