"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !exports.hasOwnProperty(p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExecutionStatusEnum = exports.TaskTypeEnum = exports.HTMLTemplatesTypesEnum = exports.SubscriptionPlansEnum = exports.ScheduledTaskModel = exports.NotificationQueueItemModel = exports.DomainEntitiesCountModel = exports.SubscriptionModel = exports.RegistrationModel = void 0;
__exportStar(require("./error-messages"), exports);
__exportStar(require("./success-messages"), exports);
var RegistrationModel = /** @class */ (function () {
    function RegistrationModel() {
        this.licensePlanId = '';
        this.firstName = '';
        this.lastName = '';
        this.email = '';
        this.telephoneNumber = '';
        this.company = '';
        this.position = '';
        this.companyAddress = '';
        this.country = '';
        this.industry = '';
        this.otherIndustry = '';
        this.isActivationEmailSent = false;
        this.isActivated = false;
    }
    return RegistrationModel;
}());
exports.RegistrationModel = RegistrationModel;
var SubscriptionModel = /** @class */ (function () {
    function SubscriptionModel() {
        this.subscriptionPlanKey = '';
        this.subscriberEmail = '';
        this.subscriptionPlanName = '';
        this.isActive = false;
    }
    return SubscriptionModel;
}());
exports.SubscriptionModel = SubscriptionModel;
var DomainEntitiesCountModel = /** @class */ (function () {
    function DomainEntitiesCountModel() {
        this.subscriberEmail = '';
        this.domainId = '';
        this.contacts = 0;
        this.projects = 0;
        this.risks = 0;
    }
    return DomainEntitiesCountModel;
}());
exports.DomainEntitiesCountModel = DomainEntitiesCountModel;
var NotificationQueueItemModel = /** @class */ (function () {
    function NotificationQueueItemModel() {
    }
    return NotificationQueueItemModel;
}());
exports.NotificationQueueItemModel = NotificationQueueItemModel;
var ScheduledTaskModel = /** @class */ (function () {
    function ScheduledTaskModel() {
    }
    return ScheduledTaskModel;
}());
exports.ScheduledTaskModel = ScheduledTaskModel;
var SubscriptionPlansEnum;
(function (SubscriptionPlansEnum) {
    SubscriptionPlansEnum[SubscriptionPlansEnum["Free2"] = -2] = "Free2";
    SubscriptionPlansEnum[SubscriptionPlansEnum["Free"] = -1] = "Free";
    SubscriptionPlansEnum[SubscriptionPlansEnum["FreeTrial"] = 0] = "FreeTrial";
    SubscriptionPlansEnum[SubscriptionPlansEnum["Admin1"] = 1] = "Admin1";
    SubscriptionPlansEnum[SubscriptionPlansEnum["Admin5"] = 2] = "Admin5";
    SubscriptionPlansEnum[SubscriptionPlansEnum["Admin10"] = 3] = "Admin10";
    SubscriptionPlansEnum[SubscriptionPlansEnum["Unlimited"] = 4] = "Unlimited";
    SubscriptionPlansEnum[SubscriptionPlansEnum["Admin25"] = 5] = "Admin25";
    SubscriptionPlansEnum[SubscriptionPlansEnum["Pro"] = 6] = "Pro";
})(SubscriptionPlansEnum = exports.SubscriptionPlansEnum || (exports.SubscriptionPlansEnum = {}));
var HTMLTemplatesTypesEnum;
(function (HTMLTemplatesTypesEnum) {
    HTMLTemplatesTypesEnum[HTMLTemplatesTypesEnum["ActivationTemplate"] = 0] = "ActivationTemplate";
    HTMLTemplatesTypesEnum[HTMLTemplatesTypesEnum["PasswordResetTemplate"] = 1] = "PasswordResetTemplate";
    HTMLTemplatesTypesEnum[HTMLTemplatesTypesEnum["InvitationTemplate"] = 2] = "InvitationTemplate";
    HTMLTemplatesTypesEnum[HTMLTemplatesTypesEnum["MitigationDueDate0days"] = 3] = "MitigationDueDate0days";
    HTMLTemplatesTypesEnum[HTMLTemplatesTypesEnum["MitigationDueDate5days"] = 4] = "MitigationDueDate5days";
    HTMLTemplatesTypesEnum[HTMLTemplatesTypesEnum["RiskSubmitted"] = 5] = "RiskSubmitted";
    HTMLTemplatesTypesEnum[HTMLTemplatesTypesEnum["AssignedAsRiskOwner"] = 6] = "AssignedAsRiskOwner";
    HTMLTemplatesTypesEnum[HTMLTemplatesTypesEnum["OccurrenceDueDate0days"] = 7] = "OccurrenceDueDate0days";
    HTMLTemplatesTypesEnum[HTMLTemplatesTypesEnum["OccurrenceDueDate5days"] = 8] = "OccurrenceDueDate5days";
})(HTMLTemplatesTypesEnum = exports.HTMLTemplatesTypesEnum || (exports.HTMLTemplatesTypesEnum = {}));
var TaskTypeEnum;
(function (TaskTypeEnum) {
    TaskTypeEnum[TaskTypeEnum["TargetMitigationDue"] = 0] = "TargetMitigationDue";
    TaskTypeEnum[TaskTypeEnum["RiskOccurrenceDate"] = 1] = "RiskOccurrenceDate";
    TaskTypeEnum[TaskTypeEnum["DatabaseBackup"] = 2] = "DatabaseBackup";
})(TaskTypeEnum = exports.TaskTypeEnum || (exports.TaskTypeEnum = {}));
var ExecutionStatusEnum;
(function (ExecutionStatusEnum) {
    ExecutionStatusEnum[ExecutionStatusEnum["Scheduled"] = 0] = "Scheduled";
    ExecutionStatusEnum[ExecutionStatusEnum["Done"] = 1] = "Done";
    ExecutionStatusEnum[ExecutionStatusEnum["ErrorOccurred"] = 2] = "ErrorOccurred";
})(ExecutionStatusEnum = exports.ExecutionStatusEnum || (exports.ExecutionStatusEnum = {}));
