import { createSlice } from '@reduxjs/toolkit';

export const signUpSlice = createSlice({
  name: 'signUp',
  initialState: {
    pricePlan: null,
    userDetails: {},
  },
  reducers: {
    setPricePlan: (state, action) => {
      state.pricePlan = action.payload.pricePlan;
    },
    setUserDetails: (state, action) => {
      state.userDetails = action.payload.userDetails;
    },
  },
});

export const { setPricePlan, setUserDetails } = signUpSlice.actions;

export default signUpSlice.reducer;
