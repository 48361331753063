import * as React from 'react';

import { useForm } from '@hooks/useForm';
import { Box, Grid, Paper, TextField, Typography } from '@material-ui/core';
import { Button } from 'elements';
import { useParams } from 'react-router-dom';
import { object, ref, string } from 'yup';
import { connect } from 'react-redux';
import { submitPassword } from '@store/actions'

const CreatePasswordPage = (props) => {
  const { token } = useParams<any>();

  const formConfig = {
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: object().shape({
      newPassword: string().min(6).required().label('New Password'),
      confirmPassword: string().required().oneOf(
        [ref('newPassword'), null],
        'Passwords must match'
      ),
    }),
    onSubmit: (values) => {
      props.submitPassword(values, token);
    },
  };

  const [form, onSubmit, errors] = useForm(formConfig);

  return (
    <Box display="flex" justifyContent="center" mt="10vh">
      <Box width={{ xs: '100%', sm: '500px' }}>
        <Paper elevation={5}>
          <Box padding={4}>
            <form onSubmit={onSubmit}>
              <Grid container direction="column" spacing={4}>
                <Grid item>
                  <Box display="flex" justifyContent="center">
                    <Typography variant="h6">Set Password for Your Account</Typography>
                  </Box>
                </Grid>
                <Grid item>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="password"
                    name="newPassword"
                    label="New Password"
                    autoComplete="new-password"
                    {...form.getFieldProps('newPassword')}
                    error={!!errors.newPassword}
                    helperText={errors?.newPassword ?? ' '}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="password"
                    name="confirmPassword"
                    label="Confirm Password"
                    autoComplete="confirm-password"
                    {...form.getFieldProps('confirmPassword')}
                    error={!!errors.confirmPassword}
                    helperText={errors?.confirmPassword ?? ' '}
                  />
                </Grid>
                <Grid item>
                  <Box py={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth
                      text="SET"
                      type="submit"
                    />
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};


const mapStateToProps = (state, ownProps = null) => ({
  ...ownProps,
});

const mapDispatchToProps = {
  submitPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePasswordPage);
