import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createHashHistory } from 'history';

import httpRequestResolver from '@store/middlewares/http-request-resolver';
import gqlResolver from './middlewares/gql-resolver';

import progressReducer from './reducers/progressSlice';
import errorsReducer from './reducers/errorsSlice';
import signInReducer from './reducers/signInSlice';
import signUpReducer from './reducers/signUpSlice';
import subscriptionSlice from './reducers/subscriptionSlice';

export const history = createHashHistory();

export const store = configureStore({
  reducer: {
    router: connectRouter(history),
    progress: progressReducer,
    errors: errorsReducer,
    signIn: signInReducer,
    signUp: signUpReducer,
    subscription: subscriptionSlice,
  },
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }).concat(httpRequestResolver, gqlResolver),
    routerMiddleware(history),
  ],
});

declare global {
  type Store = { [K in keyof typeof store]: ReturnType<typeof store[K]> };
  export type RootState = ReturnType<typeof store.getState>;
  export type AppDispatch = typeof store.dispatch;
  export type RootAction = ReturnType<typeof store.dispatch>;
}
