import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import ProfilePage from './dashboard/ProfilePage';
import DownloadsPage from './dashboard/DownloadsPage';

const MainLayout = () => (
  <Switch>
    <Route exact path="/profile">
      <ProfilePage />
    </Route>
    <Route exact path="/downloads">
      <DownloadsPage />
    </Route>
    <Route path="">
      <DownloadsPage />
    </Route>
  </Switch>
);

const mapStateToProps = (state, ownProps = null) => ({
  ...ownProps,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
