import { push } from 'connected-react-router';
import { navigateToStripePortal, setSuccess } from '.';
import { saveSessionData } from './session';
import { successMessages } from '@prm/core'

const { createAction } = require('@reduxjs/toolkit');

const postAccountCredentials = createAction('fetch', (registrationData) => ({
  payload: {
    endpoint: 'registrations/initiate',
    method: 'POST',
    auth: false,
    body: registrationData,
    normalize: (response) => {
      return { response };
    },
    onSuccess: [
      () => setSuccess({ successMessage: successMessages.accountCreated}),
      () => push('/check-email', { message: successMessages.accountCreated })
    ],
  },
}));

const submitCompanyDetails = createAction('fetch', (companyData, token) => ({
  payload: {
    endpoint: `registrations/company/${token}`,
    method: 'POST',
    auth: false,
    body: companyData,
    normalize: (response) => {
      const { user, access_token, stripeSessionUrl } = response;
      return { user, token: access_token, redirectUrl: stripeSessionUrl };
    },
    onSuccess: [
      saveSessionData,
      (payload) => {
        if(payload.redirectUrl){
          return navigateToStripePortal(payload);
        } else {
          return () => push('/')
        }
      },
    ],
  },
}));

const submitPassword = createAction('fetch', (password, token) => ({
  payload: {
    endpoint: `registrations/password/${token}`,
    method: 'POST',
    auth: false,
    body: { password },
    normalize: (response) => {
      const { user, access_token } = response;
      return { user, token: access_token };
    },
    onSuccess: [
      saveSessionData, 
      () => push(`/`)
    ],
  },
}));

export { postAccountCredentials, submitCompanyDetails, submitPassword };
