import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { setLoading } from '@store/reducers/progressSlice';

const navigateToStripePortal = createAsyncThunk(
  'navigateToStripePortal',
  async ({ redirectUrl }: any, thunkApi) => {
    thunkApi.dispatch(setLoading(true));
    window.location.href = redirectUrl;
  }
);

const initStripeSession = createAction('fetch', () => {
  const currentUrl = window.location.href;

  return {
    payload: {
      endpoint: `stripe/new-session?returnUrl=${currentUrl}`,
      method: 'GET',
      auth: true,
      normalize: (response) => ({ redirectUrl: response.redirectUrl }),
      onSuccess: navigateToStripePortal,
    },
  };
});

export { initStripeSession, navigateToStripePortal };
