import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Divider,
  Grid,
  Hidden,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import Button from 'elements/Button';
import { connect } from 'react-redux';
import { loadProfileData, updateProfileData } from '@store/actions';
import { useForm } from '@hooks/useForm';
import { object, string } from 'yup';
import countryList from 'constants/country-list';
import industryList from 'constants/industry-list';
import PageHeader from 'elements/PageHeader';

const ProfilePage = (props) => {
  const profileData = props.profileData || {};

  const formConfig = {
    enableReinitialize: true,
    initialValues: {
      firstName: props.profileData?.firstName || '',
      surname: props.profileData?.surname || '',
      companyAddress: props.profileData?.companyAddress || '',
      companyName: props.profileData?.companyName || '',
      country: props.profileData?.country || '',
      industry: props.profileData?.industry || '',
      otherIndustry: props.profileData?.otherIndustry || '',
      jobTitle: props.profileData?.jobTitle || '',
      telephone: props.profileData?.telephone || '',
    },
    validationSchema: object().shape({
      firstName: string().required().label('First Name'),
      surname: string().required().label('Surname'),
      country: string().required().label('Country'),
    }),
    onSubmit: (values) => {
      const changes = Object.keys(values).reduce((diff, key) => {
        if (profileData[key] === values[key]) return diff;
        return {
          ...diff,
          [key]: values[key],
        };
      }, {});
      props.updateProfileData(changes);
      // props.login(values);
    },
  };

  const [form, onSubmit, errors] = useForm(formConfig);

  const [isOtherIndustrySelected, setIsOtherIndustrySelected] = useState(false);
  const handleIndustryChange = (e) => {
    const selectedIndustry = e.target.value;
    form.setFieldValue('industry', selectedIndustry);

    if (selectedIndustry === 'Other') {
      setIsOtherIndustrySelected(true);
    } else {
      setIsOtherIndustrySelected(false);
      form.setFieldValue('otherIndustry', '');
    }
  };

  useEffect(() => {
    if (!props.profileData) {
      props.loadProfileData();
    }

    if(form.values.industry === 'Other') {
      setIsOtherIndustrySelected(true);
    }
  }, [props, form]);

  return (
    <Box display="flex" justifyContent="center" mt={{ xs: '0px', sm: '20px' }} pb={8}>
      <Container maxWidth="md" disableGutters>
        <PageHeader>Update your profile details</PageHeader>
        <Box>
          <Paper elevation={5}>
            <Box px={{ xs: 2, sm: 8 }} py={4}>
              <form onSubmit={onSubmit} noValidate>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={5}>
                    <Grid container direction="column" spacing={2}>
                      <Grid item>
                        <Typography variant="h6">
                          Account Credentials
                        </Typography>
                      </Grid>
                      <Grid item>
                        <TextField
                          required
                          fullWidth
                          label="First Name"
                          variant="outlined"
                          inputProps={{
                            style: { textTransform: 'capitalize' },
                          }}
                          id="firstName"
                          name="firstName"
                          {...form.getFieldProps('firstName')}
                          error={!!errors.firstName}
                          helperText={errors?.firstName ?? ' '}
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          required
                          fullWidth
                          label="Surname"
                          variant="outlined"
                          inputProps={{
                            style: { textTransform: 'capitalize' },
                          }}
                          id="surname"
                          name="surname"
                          {...form.getFieldProps('surname')}
                          error={!!errors.surname}
                          helperText={errors?.surname ?? ' '}
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          fullWidth
                          label="Telephone"
                          variant="outlined"
                          inputProps={{
                            style: { textTransform: 'capitalize' },
                          }}
                          {...form.getFieldProps('telephone')}
                          error={!!errors.telephone}
                          helperText={errors?.telephone ?? ' '}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Hidden smDown>
                    <Grid container item md={1} justify="center">
                      <Divider orientation="vertical" />
                    </Grid>
                  </Hidden>
                  <Grid item xs={12} md={6}>
                    <Grid container direction="column" spacing={2}>
                      <Grid item>
                        <Typography variant="h6">
                          Company Information
                        </Typography>
                      </Grid>
                      <Grid item>
                        <TextField
                          required
                          fullWidth
                          label="Company Name"
                          variant="outlined"
                          inputProps={{
                            style: { textTransform: 'capitalize' },
                          }}
                          {...form.getFieldProps('companyName')}
                          error={!!errors.companyName}
                          helperText={errors?.companyName ?? ' '}
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          required
                          fullWidth
                          label="Job Title"
                          variant="outlined"
                          inputProps={{
                            style: { textTransform: 'capitalize' },
                          }}
                          {...form.getFieldProps('jobTitle')}
                          error={!!errors.jobTitle}
                          helperText={errors?.jobTitle ?? ' '}
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          fullWidth
                          label="Country"
                          select
                          variant="outlined"
                          {...form.getFieldProps('country')}
                          error={!!errors.country}
                          helperText={errors?.country ?? ' '}
                        >
                          <MenuItem key="''" value="''" />
                          {countryList.map((country) => (
                            <MenuItem key={country.code} value={country.name}>
                              {country.emojiIcon} {country.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item>
                        <TextField
                          required
                          fullWidth
                          label="Company Address"
                          variant="outlined"
                          {...form.getFieldProps('companyAddress')}
                          error={!!errors.companyAddress}
                          helperText={errors?.companyAddress ?? ' '}
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          fullWidth
                          label="Industry"
                          select
                          variant="outlined"
                          {...form.getFieldProps('industry')}
                          error={!!errors.industry}
                          helperText={errors?.industry ?? ' '}
                          onChange={handleIndustryChange}
                        >
                          <MenuItem key="''" value="''" />
                          {industryList.map((industy) => (
                            <MenuItem key={industy} value={industy}>
                              {industy}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      {isOtherIndustrySelected && (
                        <Grid item>
                          <TextField
                            fullWidth
                            label="Other Industry"
                            variant="outlined"
                            name="otherIndustry"
                            {...form.getFieldProps('otherIndustry')}
                            error={!!errors.otherIndustry}
                            helperText={errors?.otherIndustry ?? ' '}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container justify="flex-end" spacing={2}>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          text="Save Changes"
                          type="submit"
                          disabled={!form.dirty}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Paper>
        </Box>
      </Container>
    </Box>
  );
};

const mapStateToProps = (state, ownProps = null) => ({
  ...ownProps,
  profileData: state.signIn.profile,
});

const mapDispatchToProps = {
  loadProfileData,
  updateProfileData,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
