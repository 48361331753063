import { createSlice } from '@reduxjs/toolkit';

const errorsSlice = createSlice({
  name: 'errors',
  initialState: {
    error: null,
    successMessage: '',
  },
  reducers: {
    setSuccess: (state, action) => {
      state.successMessage = action.payload.successMessage;
    },
    setError: (state, action) => {
      state.error = action.payload.error;
    },
    removeError: (state) => {
      state.error = null;
    },
  },
});

export default errorsSlice.reducer;

export const { setError, removeError, setSuccess } = errorsSlice.actions;
