import React from 'react';

import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { history, store } from '@store';

import { StylesProvider } from '@material-ui/styles';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import { loadSessionData } from '@store/actions';
import MainLayout from 'containers/MainLayout';

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  }
});

(function loadInitialData() {
  store.dispatch<any>(loadSessionData());
})();

function App() {

  return (
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <HashRouter>
              <MainLayout />
            </HashRouter>
          </ConnectedRouter>
        </Provider>
      </MuiThemeProvider>
    </StylesProvider>
  );
}

export default App;
