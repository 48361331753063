import React from 'react';
import { Link as MatLink } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

export default function Link(props) {
  return (
    <MatLink component={RouterLink} {...props}>
      {props.children}
    </MatLink>
  );
}
