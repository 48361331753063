import { successMessages } from '@prm/core';
import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { setError, setSuccess } from '@store/reducers/errorsSlice';
import { signIn, signOut } from '@store/reducers/signInSlice';
import { push } from 'connected-react-router';

const setPassword = createAction('fetch', (token, newPassword) => ({
  payload: {
    endpoint: 'auth/set-password',
    method: 'POST',
    body: { token, newPassword },
    auth: false,
    onSuccess: [
      ()=> push('/login'),
      ()=> setError({ error: new Error('Your password has been successfully reset.') }),
    ],
  },
}));

const saveSessionData = createAsyncThunk(
  'saveSessionData',
  async (sessionData, thunkAPI) => {
    localStorage.setItem('session', JSON.stringify(sessionData));
    thunkAPI.dispatch(signIn(sessionData));
  }
);

const loadSessionData = createAsyncThunk(
  'loadSessionData',
  async (args, thunkAPI) => {
    const sessionData = JSON.parse(localStorage.getItem('session'));
    if (sessionData) {
      thunkAPI.dispatch(signIn(sessionData));
    }
  }
);

const discardSession = createAsyncThunk(
  'discardSession',
  async (args, thunkAPI) => {
    localStorage.removeItem('session');
    thunkAPI.dispatch(signOut());
  }
);

const login = createAction('fetch', (username, password) => ({
  payload: {
    endpoint: 'auth/login',
    method: 'POST',
    body: { username, password },
    normalize: (response) => {
      const { user, access_token } = response;
      return { user, token: access_token };
    },
    onSuccess: [
      saveSessionData,
      () => push('/downloads'),
    ]
  },
}));

const requestResetPassword = createAction('fetch', (email) => ({
  payload: {
    endpoint: 'auth/reset-password',
    method: 'POST',
    body: {
      email,
      setPasswordUrl: `${window.location.protocol}//${
        window.location.hostname
      }${
        window.location.port ? `:${window.location.port}` : ''
      }/#/set-password`,
    },
    auth: false,
    onSuccess: [
      () => setSuccess({ successMessage: successMessages.passwordResetMailSent}),
      () => push('/check-email')
    ],
  },
}));


const logout = createAsyncThunk('logout', async (args, thunkAPI) => {
  await thunkAPI.dispatch(discardSession());
  thunkAPI.dispatch(signOut());
  thunkAPI.dispatch(push('/log-in'));
});

export {
  login,
  logout,
  requestResetPassword,
  setPassword,
  saveSessionData,
  loadSessionData,
};
