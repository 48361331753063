import { countries } from 'countries-list';

const countryList = Object.keys(countries).map((code) => {
  const country = countries[code];
  return {
    code,
    name: country.name,
    emojiIcon: country.emoji,
    telephoneCode: `+${country.phone.split(',')[0]}`,
  };
});

export default countryList;