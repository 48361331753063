import { Box, Container, Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';
import Button from 'elements/Button';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import PageHeader from 'elements/PageHeader';

const DownloadsPage = () => {
  return (
    <Box display="flex" justifyContent="center" mb="20px">
      <Container maxWidth="md" disableGutters>
        <PageHeader>You can download everything you need here.</PageHeader>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper elevation={5}>
              <Box px={{ xs: 2, sm: 8 }} py={4}>
                <Box pb={4}>
                  <Typography variant="h4">Windows OS</Typography>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    startIcon={<CloudDownloadIcon />}
                    onClick={(event) => {
                      event.preventDefault();
                      window.open('https://storage.cloud.google.com/prm-downloads/PRMSetup.exe');
                    }}
                  >
                    Download Setup File
                  </Button>
                </Box>
                <Box>
                  <Typography variant="h6">Requirements</Typography>
                  <Box>
                    <ul>
                      <li>
                        <Typography variant="subtitle1">
                          Windows OS 86x/64x, XP with Service Pack 4, Vista, 7,
                          8, 10
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="subtitle1">
                          Microsoft .Net Framework 4.5.1
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="subtitle1">
                          Internet connection will be required on first start
                        </Typography>
                      </li>
                    </ul>
                    <Grid container spacing={5}>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          startIcon={<PictureAsPdfIcon />}
                          onClick={(event) => {
                            event.preventDefault();
                            window.open(
                              '/downloads/Project_Risk_Manager_-_Operating_Manual_(v1.2.1).pdf'
                            );
                          }}
                        >
                          User Manual
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          startIcon={<PictureAsPdfIcon />}
                          onClick={(event) => {
                            event.preventDefault();
                            window.open(
                              'https://www.project-risk-manager.com/wp-content/uploads/2017/05/Project-Risk-Manager-Software-Brochure-v1.2.pdf'
                            );
                          }}
                        >
                          Features Brochure
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper elevation={5}>
              <Box px={{ xs: 2, sm: 8 }} py={4}>
                <Box>
                  <Typography variant="h6">MacOS - Coming soon...</Typography>
                  <Box>
                    <ul>
                      <li>
                        <Typography variant="subtitle1">
                          Only 64bit binaries are provided for macOS.
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="subtitle1">
                          The minimum macOS version supported is macOS 10.10
                          (Yosemite). Including support for Apple Silicon
                          (arm64) devices.
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="subtitle1">
                          Internet connection will be required on first start
                        </Typography>
                      </li>
                    </ul>
                  </Box>
                </Box>
                <Box>
                  <Typography variant="h6">Linux - Coming soon...</Typography>
                  <Box>
                    <ul>
                      <li>
                        <Typography variant="subtitle1">
                          Ubuntu 14.04 and newer
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="subtitle1">
                          Fedora 24 and newer
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="subtitle1">
                          Debian 8 and newer
                        </Typography>
                      </li>
                    </ul>
                  </Box>
                </Box>
                <Box>
                  <Typography variant="h6">
                    Mobile (IOS, Android) - Coming soon...
                  </Typography>
                  <Box>
                    <ul>
                      <li>
                        <Typography variant="subtitle1">iOS 11+</Typography>
                      </li>
                      <li>
                        <Typography variant="subtitle1">Android 7+</Typography>
                      </li>
                    </ul>
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default DownloadsPage;
