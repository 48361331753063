import { Box, Typography } from '@material-ui/core';
import * as React from 'react';

const PageHeader = (props) => {
  return (
    <Box py={2} display="flex" justifyContent="center">
      <Typography variant="h5" style={{ color: 'whitesmoke' }} align="center">
        {props.children}
      </Typography>
    </Box>
  );
};

export default PageHeader;
