import React from 'react';
import {
  Box,
  Grid,
  Paper,
  TextField,
} from '@material-ui/core';
import Button from 'elements/Button';
import { connect } from 'react-redux';
import { object, ref, string } from 'yup';
import { useForm } from '@hooks/useForm';
import { postAccountCredentials } from '@store/actions';
import { useParams } from 'react-router';
import PageHeader from 'elements/PageHeader';

const AccountCredentialsPage = (props) => {
  const { subscriptionPlanId } = useParams<any>();

  const formConfig = {
    enableReinitialize: true,
    initialValues: {
      firstName: '',
      surname: '',
      email: '',
      password: '',
      confirmPassword: '',
      subscriptionPlanId,
    },
    validationSchema: object().shape({
      firstName: string().required().label('First Name'),
      surname: string().required().label('Surname'),
      email: string().email().required().label('Email'),
      password: string().min(6).required().label('New Password'),
      confirmPassword: string()
        .required()
        .oneOf([ref('password'), null], 'Passwords must match'),
      subscriptionPlanId: string().required(),
    }),
    onSubmit: (values) => {
      props.postAccountCredentials(values);
    },
  };

  const [form, onSubmit, errors] = useForm(formConfig);

  return (
    <>
      <PageHeader>Register new account</PageHeader>
      <Box display="flex" justifyContent="center">
        <Box width={{ xs: '100%', sm: '400px' }}>
          <Paper elevation={5}>
            <Box padding={{ xs:2, sm: 4}} pt={{xs: 4}} >
              <form onSubmit={onSubmit} noValidate>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <TextField
                      required
                      fullWidth
                      label="First Name"
                      variant="outlined"
                      inputProps={{ style: { textTransform: 'capitalize' } }}
                      name="firstName"
                      {...form.getFieldProps('firstName')}
                      error={!!errors.firstName}
                      helperText={errors?.firstName ?? ' '}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      required
                      fullWidth
                      label="Surname"
                      variant="outlined"
                      inputProps={{ style: { textTransform: 'capitalize' } }}
                      name="surname"
                      {...form.getFieldProps('surname')}
                      error={!!errors.surname}
                      helperText={errors?.surname ?? ' '}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      required
                      fullWidth
                      label="Email"
                      variant="outlined"
                      name="email"
                      {...form.getFieldProps('email')}
                      error={!!errors.email}
                      helperText={errors?.email ?? ' '}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      variant="outlined"
                      type="password"
                      name="password"
                      label="Password"
                      autoComplete="new-password"
                      {...form.getFieldProps('password')}
                      error={!!errors.password}
                      helperText={errors?.password ?? ' '}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      variant="outlined"
                      type="password"
                      name="confirmPassword"
                      label="Confirm Password"
                      autoComplete="new-password"
                      {...form.getFieldProps('confirmPassword')}
                      error={!!errors.confirmPassword}
                      helperText={errors?.confirmPassword ?? ' '}
                    />
                  </Grid>
                  <Grid item>
                    <Box py={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth
                        text="Register"
                        type="submit"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Paper>
        </Box>
      </Box>
    </>
  );
};

const mapStateToProps = (state, ownProps = null) => ({
  ...ownProps,
});

const mapDispatchToProps = {
  postAccountCredentials,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountCredentialsPage);
