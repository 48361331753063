import React from 'react';
import { connect } from 'react-redux';
import { IconButton, Snackbar } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { removeError } from '@store/actions';

const Notifications = ({ dismissError, show, error }) => {
  const handleCloseClick = () => {
    dismissError();
  };

  return (
    <Snackbar
      style={{ top: '90px' }}
      open={show}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={6000}
      onClose={handleCloseClick}
      message={error ? error.message : ''}
      action={
        <IconButton color="secondary" size="small" onClick={handleCloseClick}>
          <HighlightOffIcon />
        </IconButton>
      }
    />
  );
};

const mapStateToProps = (state, ownProps = null) => ({
  show: !!state.errors.error,
  error: state.errors.error,
  ...ownProps,
});

const mapDispatchToProps = {
  dismissError: removeError,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
